<template>
    <v-container>
        <v-card
        elevation="2"
        outlined
        >
        <p class="ml-5 text-h5">Ваза гранитная</p>
        <v-row class="ml-2 mr-2">
        <v-col cols="3" lg="4" md="6" sm="6">
            Размеры
            <v-text-field
            v-model="objVases.dimensionsVase"
            dense
            outlined
        >
            </v-text-field>
        </v-col>   
        <v-col cols="3" lg="4" md="6" sm="6">
            Название модели
            <v-text-field
            v-model="objVases.nameVase"
            dense
            outlined
        >
            </v-text-field>
        </v-col>   
        </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            objVases: {
                dimensionsVase: '',
                nameVase: '',
            },
            objCopyStore: {},
        }
    },
    mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "objVases": {
    handler() {
        for(const key in this.objVases){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.objVases[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
  },
}
</script>