<template>
    <div class="ml-4 mt-4 mb-4 align-center" @click="exportDocx">
    <v-btn color="primary">Создать заказ</v-btn>
    </div>
</template>

<script>
// import { Document, ImageRun, Packer, Paragraph, TextRun } from "docx";
import { Document, convertInchesToTwip, WidthType, BorderStyle,
 AlignmentType, Packer, Paragraph, TableCell, TableRow, TextRun, Table,} from "docx";
 import { mapGetters } from "vuex";
// import {fs} from "fs";
// import * as fs from "fs";
import moment from "moment";

// import img from "../assets/logo.png"
// const img = require(`this.objStore.sketch.image`);
// import { saveAs } from 'file-saver'; // you can use this also
const FileSaver = require("file-saver");

export default {
  name: "loadFile",
  components: {

  },
  data() {

    return {
      number: 0,
      date: moment().format("DD.MM.YYYY"),
    };
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
    clientName() {
      let name = '';
      if (this.objStore?.fio) {
        let arr = this.objStore.fio.split(" ")
        name = `${arr[0].toUpperCase()}  ${arr[1].slice(0,1)}.${arr[2].slice(0,1)}.`;
      }
      return name;
    }
  },
  methods: {
    exportDocx() {
      // Create a new Document an save it in a variable
      const doc = new Document({
        styles: {
        paragraphStyles: [
            {
                id: "myWonkyStyle",
                name: "My Wonky Style",
                basedOn: "Normal",
                next: "Normal",
                run: {
                    color: "990000",
                    italics: true,
                    size: 12,
                },
                paragraph: {
                    indent: {
                        left: convertInchesToTwip(0.5),
                    },
                    spacing: {
                        line: 276,
                    },
                },
            },
        ],
    },
   
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `СПЕЦИФИКАЦИЯ № ${this.objStore.number}`,
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                    
                    spacing: {
                      after: 300,
                  },
                  children: [
                    new TextRun({
                      text: `к Договору № ${this.objStore.number} от ${this.date || ''}`,
                      bold: false,
                      size: 18,
                    }),
                  ],
                alignment: AlignmentType.CENTER,
              }),
              
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Между `,
                    size: 18,
                  }),
                  new TextRun({
                    text: `ИСПОЛНИТЕЛЕМ `,
                    bold: false,
                    size: 18,
                  }),
                  new TextRun({
                    text: `Индивидуальный предприниматель Шамак Владимир Валентинович`,
                    size: 18,
                    italics: true,
                    bold: true,
                    font:{
                        name: "Arial",
                    }
                  }),
                ],
                   
                 
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `и`,
                    bold: false,
                    size: 18,
                  }),
                ],
                border: {
                    // top: {
                    //     color: "auto",
                    //     space: 1,
                    //     style: "single",
                    //     size: 6,
                    // },
                },
                // indent: {
                //     left: 2200,
                //   },
                
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `ЗАКАЗЧИК:`,
                    bold: false,
                    size: 18,
                  }),
                  new TextRun({
                    text: `      ${this.objStore.fio}`,
                    bold: true,
                    size: 18,
                    italics: true,
                    }),
                  ],    
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '(Фамилия, Имя, Отчество)',
                    bold: false,
                    size: 14,
                  }),
                ],
                alignment: AlignmentType.CENTER,
                border: {
                    top: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                },
                indent: {
                          left: 1300,
                        },
                
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                indent: {
                          left: 1800,
                        },
                spacing: {
                      before: 200,
                  },
                  children: [
                  new TextRun({
                    text: `конт. тел. Заказчика        ${this.objStore.phone}`,
                    bold: false,
                    size: 18,
                  }),
                ],
                
              }),
              new Paragraph({
                text: '',
                border: {
                    top: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                },
                indent: {
                          left: 3900,
                        },
              }),
               new Paragraph({
                children: [
                  new TextRun({
                    text: 'Уважаемый ЗАКАЗЧИК!!!',
                    bold: true,
                //  indent: {
                //    top: 1200,
                //  }
                  }),
                ],
                border: {
                    top: {
                        color: "auto",
                        space: 100,
                        style: "single",
                        size: 6,
                    },
                    left: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    right: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                },
                size: 18,
                alignment: AlignmentType.CENTER,
              }),
               new Paragraph({
                children: [
                  new TextRun({
                    text: 'Обращаем Ваше внимание на то, что каждая плита из природного камня неповторима и отличается только ей присущими прожилками, окраской и расположением отдельных кристаллов.',
                    bold: false,
                    size: 18,
                  }),
                ],
                border: {
                    left: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    right: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    bottom: {
                        color: "auto",
                        space: 200,
                        style: "single",
                    },
                },
                
                size: 1,
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'ВНИМАНИЕ!!! ',
                    bold: true,
                    size: 18,
                    italics: true,
                  }),
                  new TextRun({
                    text: 'При плохом качестве фотографии (любительская, цветная красная, расплывчатая, сильно деформированная, не ясные черты лица) Исполнитель НЕ ГАРАНТИРУЕТ качественное исполнение графического портрета',
                    bold: false,
                    size: 18,
                    font: "Arial",
                    italics: true,
                  })
                ],
                border: {
                  top: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    left: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    right: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    bottom: {
                        color: "auto",
                        space: 100,
                        style: "single",
                        size: 6,
                    },
                },
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Элементы надгробий соответствуют СТБ363-2007',
                    bold: true,
                    italics: true,
                    size: 18,
                  }),
                ],
                border: {
                    left: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    right: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    bottom: {
                        color: "auto",
                        space: 100,
                        style: "single",
                        size: 6,
                    },
                    top: {
                      space: 100,
                    }
                },
                alignment: AlignmentType.CENTER,
                spacing: {
                      after: 300,
                  },
              }),
              
            new Table({
    columnWidths: [3000, 2000, 5000],
    rows: [
        new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Наименование',
                            bold: true,
                            size: 18
                          }),
                        ],
                        border: {
                            top: {
                              space: 2,
                            },
                            bottom: {
                              space: 2,
                            }
                        },

                            alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [new Paragraph({
                      children: [
                          new TextRun({
                            text:"РАЗМЕРЫ",
                            bold: true,
                            size: 18
                          }),
                        ],
                        border: {
                            top: {
                              space: 2,
                            },
                            bottom: {
                              space: 2,
                            }
                        },
                    alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [new Paragraph({
                        children: [new TextRun({
                          text:"ПРИМЕЧАНИЕ", 
                          bold: true,
                          size: 18
                        }),],
                        border: {
                            top: {
                              space: 2,
                            },
                            bottom: {
                              space: 2,
                            }
                        },
                            alignment: AlignmentType.CENTER,
                    }),
                    ],
                    alignment: AlignmentType.CENTER,
                }),
            ],
        }),
        ]
              }),
            new Paragraph({
                children: [
                  new TextRun({
                    text: ' КЛАДБИЩЕ',
                    bold: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ` ${this.objStore.cemetery}`,
                    bold: false,
                    underline: true,
                    size: 18
                  }),
                ],
                border: {
                    left: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    right: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                },
                alignment: AlignmentType.LEFT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: ' Сектор',
                    bold: true,
                    size: 18
                  }),
                  new TextRun({
                    text: `${this.objStore.sector}`,
                    bold: false,
                    border: true,
                    underline: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ' участок',
                    bold: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ` ${this.objStore.plot}.`,
                    bold: false,
                    border: true,
                    underline: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ' ряд',
                    bold: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ` ${this.objStore.row}.`,
                    bold: false,
                    border: true,
                    underline: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ' место',
                    bold: true,
                    size: 18
                  }),
                  new TextRun({
                    text: ` ${this.objStore.place}.`,
                    bold: false,
                    border: true,
                    underline: true,
                    size: 18
                  }),
                ],
                border: {
                    left: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                    right: {
                        color: "auto",
                        space: 1,
                        style: "single",
                        size: 6,
                    },
                
                },
                alignment: AlignmentType.LEFT,
              }),
              new Table({
              columnWidths: [3000, 2000, 5100],
              rows: [
                  new TableRow({
                      children: [
                      new TableCell({
                      width: {
                          size: 3000,
                          type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                      new TextRun({
                        text: ' 1.СТЕЛА',
                        bold: true,
                        size: 17
                      }),
                      ]
                    }),
                    new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ' эскиз № ',
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.sketch}`,
                                            // text: ` ${this.objStore.sketch.name || ''}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 1800,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                      ],
                  }),
                  new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: '   гранит ',
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.granit}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 1800,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                      ],
                  }),
                    
                    ],
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: '',
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsStele}`,
                            bold: false,
                            size: 17
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 5100,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: '',
                        alignment: AlignmentType.LEFT,
                    }),
                    new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска задней стороны стелы полирована `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 3800,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.polishedBevelStella === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
            ],
        }),
        new TableRow({
                      children: [
                      new TableCell({
                      width: {
                          size: 3000,
                          type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                      new TextRun({
                        text: ' 2. ТУМБА (подставка)',
                        bold: true,
                        size: 17
                      }),
                      ]
                    }),
                    
                    ],
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsPedestal}`,
                            bold: false,
                            size: 17,
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 5100,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска по краям тумбы снята `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2500,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.polishedBevelPedestal === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` полирована `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 1100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.polishedBevelPedestal2 === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                    
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
            ],
        }),
        new TableRow({
                      children: [
                      new TableCell({
                        rowSpan: 2,
                      width: {
                          size: 3000,
                          type: WidthType.DXA,
                    },
                    
                    
                    children: [
                      new Paragraph({
                        children: [
                      new TextRun({
                        text: ' 3. ЦВЕТНИК',
                        bold: true,
                        size: 17,
                      }),
                      
                      ],
                      borders: {           
                      bottom: {
                          style: BorderStyle.DOT_DOT_DASH,
                          size: 3,
                          color: "ff8000",
                          border: false
                      },
                  },
                    }),
                    
                    ],
                    
                }),
                new TableCell({
                  rowSpan: 2,
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsTsvetnik}`,
                            bold: false,
                            size: 17
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Торцы поперечки цветника полированы `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 4900,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaTsvetnik1 ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                              
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
                
            ],
        }),
         new TableRow({
                      children: [
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска по краям цветника снята `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2900,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaTsvetnik2 === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` полирована `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 1100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaTsvetnik3 === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
                
            ],
        }),
        new TableRow({
                      children: [
                      new TableCell({
                      width: {
                          size: 3000,
                          type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                      new TextRun({
                        text: ' 4. НАДГРОБНАЯ ВСТАВКА/ ПЛИТА',
                        bold: true,
                        size: 17
                      }),
                      ]
                    }),
                    
                    ],
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsVstavka}`,
                            bold: false,
                            size: 17,
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска на плите снята `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2500,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaVstavka1 ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` полирована `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 1100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaVstavka2 ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
            ],
        }),
       
        new TableRow({
                      children: [
                      new TableCell({
                        rowSpan: 2,
                      width: {
                          size: 3000,
                          type: WidthType.DXA,
                    },
                    
                    
                    children: [
                      new Paragraph({
                        children: [
                      new TextRun({
                        text: ' 5. Ограда',
                        bold: true,
                        size: 17,
                      }),
                      
                      ],
                      borders: {           
                      bottom: {
                          style: BorderStyle.DOT_DOT_DASH,
                          size: 3,
                          color: "ff8000",
                          border: false
                      },
                  },
                    }),
                    new Paragraph({
                        children: [
                      new TextRun({
                        text: '',
                        bold: true,
                        size: 18,
                      }),
                      
                      ],
                      borders: {           
                      bottom: {
                          style: BorderStyle.DOT_DOT_DASH,
                          size: 3,
                          color: "ff8000",
                          border: false
                      },
                  },
                    }),
                    new Paragraph({
                        children: [
                      new TextRun({
                        text: '  5.1 Столбики',
                        bold: true,
                        size: 17,
                      }),
                      
                      ],
                      borders: {           
                      bottom: {
                          style: BorderStyle.DOT_DOT_DASH,
                          size: 3,
                          color: "ff8000",
                          border: false
                      },
                  },
                    }),
                    new Paragraph({
                        children: [
                      new TextRun({
                        text: ' ',
                        bold: true,
                        size: 18,
                      }),
                      
                      ],
                      borders: {           
                      bottom: {
                          style: BorderStyle.DOT_DOT_DASH,
                          size: 3,
                          color: "ff8000",
                          border: false
                      },
                  },
                    }),
                    new Paragraph({
                        children: [
                      new TextRun({
                        text: '      5.2 Цоколь (5*8)',
                        bold: true,
                        size: 17,
                      }),
                      
                      ],
                      borders: {           
                      bottom: {
                          style: BorderStyle.DOT_DOT_DASH,
                          size: 3,
                          color: "ff8000",
                          border: false
                      },
                  },
                    }),
                    
                    ],
                    
                }),
                new TableCell({
                  rowSpan: 2,
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsFence}`,
                            bold: false,
                            size: 17
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: ``,
                            bold: false,
                            size: 18
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsColumns}`,
                            bold: false,
                            size: 17
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: ``,
                            bold: false,
                            size: 18
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsPlinth}`,
                            bold: false,
                            size: 17
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска на гранитных элементах ограды снята `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 4900,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaFence === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          }),
                              
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
                
            ],
        }),
         new TableRow({
                      children: [
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` полирована `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 1100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaFence2 === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  },
                                }),
                                
                              ],
                          }),
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Количество: ${this.objStore.quantityColumns} штук`,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      right: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                
                              ],
                          }),
                          new TableRow({
                      children: [
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска по краям снята `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 3500,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaColumns === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` полирована              `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {

                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaColumns2 === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          
                          }),
                      ],
                  }),
                    ],
                    borders: {
                                top: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                                right: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                                bottom: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                                left: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                              },
                                  alignment: AlignmentType.LEFT,
                              }),
                              
                          ],
                      }),
                      new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Количество: ${this.objStore.quantityPlinth} штук`,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      right: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                
                              ],
                          }),
                          new TableRow({
                      children: [
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                      new Table({
                      rows: [
                          new TableRow({
                              children: [
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` Фаска по краям снята `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {
                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 3500,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaPlinth === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                                new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` полирована              `,
                                            bold: false,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      borders: {

                                      left: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "00FF00",
                                          border: false
                                      },
                                      top: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                      bottom: {
                                          style: BorderStyle.DOT_DOT_DASH,
                                          size: 3,
                                          color: "ff8000",
                                          border: false
                                      },
                                  },
                                  width: {
                                    size: 2100,
                                    type: WidthType.DXA,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                  }),
                                  new TableCell({
                                      children: [new Paragraph({
                                         children: [
                                          new TextRun({
                                            text: ` ${this.objStore.faskaPlinth2 === '1' ? '√' : 'x'}`,
                                            bold: true,
                                            size: 17
                                          }),
                                          ]
                                      })],
                                      width: {
                                    size: 200,
                                    type: WidthType.DXA,
                                  }
                                }),
                              ],
                          
                          }),
                      ],
                  }),
                    ],
                    borders: {
                                top: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                                right: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                                bottom: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                                left: {
                                    style: BorderStyle.DOT_DOT_DASH,
                                    size: 3,
                                    color: "ff8000",
                                    border: false
                                },
                              },
                                  alignment: AlignmentType.LEFT,
                              }),
                              
                          ],
                      }),
                      ],
                  }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
                
            ],
        }),
        new TableRow({
                      children: [
                      new TableCell({
                      width: {
                          size: 3000,
                          type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                      new TextRun({
                        text: ' 6. Ваза',
                        bold: true,
                        size: 18
                      }),
                      ]
                    }),
                    
                    ],
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.dimensionsVase}`,
                            bold: false,
                            size: 17,
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                    ],
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                    new Paragraph({
                      children:[
                          new TextRun({
                            text: `${this.objStore.nameVase}`,
                            bold: false,
                            size: 17,
                          }),
                        ],
                        alignment: AlignmentType.LEFT,
                    })
                    ],
                }),
            ],
        }),
       
        
        ]
              }),
              new Table({
              columnWidths: [10100],
              rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 3000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                              children: [new TextRun({
                            text:"ДОПОЛНЕНИЕ",
                            size: 18,
                            bold: true,

                              })],
                              border: {
                                top: {
                                  space: 2,
                                },
                                bottom: {
                                  space: 2,
                                }
                              },
                                  alignment: AlignmentType.CENTER,
                          })
                          ],
                      }),
                  ],
              }),
              ]
              }),
              new Table({
              columnWidths: [5000, 5100],
              rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 3000,
                              type: WidthType.DXA,
                          },
                          children: [
                            new Table({
                              columnWidths: [4000, 1000],
                              rows: [
                              new TableRow({
                                  children: [
                                      new TableCell({
                                          width: {
                                              size: 4000,
                                              type: WidthType.DXA,
                                          },
                                          children: [new Paragraph({
                                              children: [new TextRun({
                                            text:" 1. СВЕРЛЕНИЕ ОТВЕРСТИЙ",
                                                size: 18,
                                              })],
                                              border: {
                                                top: {
                                                  space: 2,
                                                },
                                                bottom: {
                                                  space: 2,
                                                }
                                              },
                                                  alignment: AlignmentType.LEFT,
                                          })
                                          ],
                                            borders: {
                                            left: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "00FF00",
                                                border: false
                                            },
                                            top: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                      new TableCell({
                                          width: {
                                              size: 1000,
                                              type: WidthType.DXA,
                                          },
                                          children: [
                                            new Paragraph({
                                              children: [new TextRun({
                                            text:`${this.objStore.holes === '1' ? '+' : ''}`,
                                                size: 18,
                                              })],
                                              border: {
                                                top: {
                                                  space: 2,
                                                },
                                                bottom: {
                                                  space: 2,
                                                }
                                              },
                                                  alignment: AlignmentType.CENTER,
                                          })
                                          ],
                                          borders: {
                                            top: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                  ],
                              }),
                              ]
                            }),
                            new Table({
                              columnWidths: [4000, 1000],
                              rows: [
                              new TableRow({
                                  children: [
                                    new TableCell({
                                          width: {
                                              size: 4000,
                                              type: WidthType.DXA,
                                          },
                                          children: [new Paragraph({
                                              children: [new TextRun({text:" 2. ОТВЕРСТИЕ ПОД МЕДАЛЬОН",
                                                size: 18,})],
                                              border: {
                                                top: {
                                                  space: 2,
                                                },
                                                bottom: {
                                                  space: 2,
                                                }
                                              },
                                                  alignment: AlignmentType.LEFT,
                                          })
                                          ],
                                          borders: {
                                            left: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "00FF00",
                                                border: false
                                            },
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                      new TableCell({
                                          width: {
                                              size: 1000,
                                              type: WidthType.DXA,
                                          },
                                          children: [
                                            new Paragraph({
                                              children: [new TextRun({
                                              text:`${this.objStore.medallionHole === '1' ? '+' : ''}`,
                                            size: 18,
                                              })],
                                              border: {
                                                top: {
                                                  space: 2,
                                                },
                                                bottom: {
                                                  space: 2,
                                                }
                                              },
                                                  alignment: AlignmentType.CENTER,
                                          })
                                          ],
                                          borders: {
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                  ]
                              }),
                              ]
                            }),
                            new Table({
                              columnWidths: [4000, 1000],
                              rows: [
                              new TableRow({
                                  children: [
                                    new TableCell({
                                          width: {
                                              size: 4000,
                                              type: WidthType.DXA,
                                          },
                                          children: [new Paragraph({
                                              children: [new TextRun({text:" 3. УВЕЛИЧЕНИЕ ФОТОГРАФИИ",
                                                size: 18,})],
                                              border: {
                                                top: {
                                                  space: 2,
                                                },
                                                bottom: {
                                                  space: 2,
                                                }
                                              },
                                                  alignment: AlignmentType.LEFT,
                                          })
                                          ],
                                          borders: {
                                           
                                            left: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                      new TableCell({
                                          width: {
                                              size: 1000,
                                              type: WidthType.DXA,
                                          },
                                          children: [
                                            new Paragraph({
                                              children: [new TextRun({
                                              text:`${this.objStore.enlargementPhoto === '1' ? '+' : ''}`,
                                            size: 18,
                                              })],
                                              border: {
                                                top: {
                                                  space: 2,
                                                },
                                                bottom: {
                                                  space: 2,
                                                }
                                              },
                                                  alignment: AlignmentType.CENTER,
                                          })
                                          ],
                                          borders: {
                                            
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                  ]
                              }),
                              ]
                            }),
                            new Table({
                              columnWidths: [4000, 1000],
                              rows: [
                              new TableRow({
                                  children: [
                                    new TableCell({
                                          width: {
                                              size: 4000,
                                              type: WidthType.DXA,
                                          },
                                          children: [new Paragraph({
                                              children: [new TextRun({text:" 4. ЗАБЕЛИТЬ ПОРТРЕТ",
                                                size: 18,})],
                                                border:{
                                                  top: {
                                                    space: 2
                                                  },
                                                  bottom: {
                                                    space: 2
                                                },
                                              },
                                                  alignment: AlignmentType.LEFT,
                                          })
                                          ],
                                          borders: {
                                            left: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                        },
                                      }),
                                      new TableCell({
                                          width: {
                                              size: 1000,
                                              type: WidthType.DXA,
                                          },
                                          children: [
                                            new Paragraph({
                                              children: [new TextRun({
                                              size: 18,
                                              })],
                                              border:{
                                                top: {
                                                    space: 2
                                                  },
                                                  bottom: {
                                                    space: 2
                                                },
                                              },
                                              text:`${this.objStore.whitenPortrait === '1' ? '+' : ''}`,
                                                  alignment: AlignmentType.CENTER,
                                          })
                                          ],
                                          borders: {
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                  ]
                              }),
                              ]
                            }),
                            new Table({
                              columnWidths: [4000, 1000],
                              rows: [
                              new TableRow({
                                  children: [
                                    new TableCell({
                                          width: {
                                              size: 4000,
                                              type: WidthType.DXA,
                                          },
                                          children: [new Paragraph({
                                              children: [new TextRun({text:" 5. ЗАБЕЛИТЬ ТЕКСТ",
                                                size: 18,})],
                                                border:{
                                                  top: {
                                                    space: 2
                                                  },
                                                  bottom: {
                                                    space: 2
                                                },
                                              },
                                                  alignment: AlignmentType.LEFT,
                                          })
                                          ],
                                          borders: {
                                            left: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                        },
                                      }),
                                      new TableCell({
                                          width: {
                                              size: 1000,
                                              type: WidthType.DXA,
                                          },
                                          children: [
                                            new Paragraph({
                                              children: [new TextRun({
                                              text:`${this.objStore.whitenText === '1' ? '+' : ''}`,
                                              size: 18,
                                              })],
                                              border:{
                                                top: {
                                                    space: 2
                                                  },
                                                  bottom: {
                                                    space: 2
                                                },
                                              },
                                                  alignment: AlignmentType.CENTER,
                                          })
                                          ],
                                          borders: {
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                  ]
                              }),
                              ]
                            }),
                            new Table({
                              columnWidths: [4000, 1000],
                              rows: [
                              new TableRow({
                                  children: [
                                    new TableCell({
                                          width: {
                                              size: 4000,
                                              type: WidthType.DXA,
                                          },
                                          children: [new Paragraph({
                                              children: [new TextRun({
                                              size: 18,
                                              text:" 6. ЗАБЕЛИТЬ ВЫБИТЫЙ ДИЗАИН",
                                              })],
                                              border:{
                                                top: {
                                                    space: 2
                                                  },
                                                  bottom: {
                                                    space: 2
                                                },
                                              },
                                                  alignment: AlignmentType.LEFT,
                                          }),
                                          ],
                                          borders: {
                                            left: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            }
                                        },
                                      }),
                                      new TableCell({
                                          width: {
                                              size: 1000,
                                              type: WidthType.DXA,
                                          },
                                          children: [
                                            new Paragraph({
                                              text:`${this.objStore.whitenDesign === '1' ? '+' : ''}`,
                                                  alignment: AlignmentType.CENTER,
                                          })
                                          ],
                                          borders: {
                                            right: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            },
                                            bottom: {
                                                style: BorderStyle.DOT_DOT_DASH,
                                                size: 3,
                                                color: "ff8000",
                                                border: false
                                            } 
                                        },
                                      }),
                                  ]
                              }),
                              ]
                            }),
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 5000,
                              type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [new TextRun({ text:` 8. Особенности заказа: `,
                              size: 17,
                              bold: true,
                              }),
                              new TextRun({ text:` ${this.objStore.orderFeatures}`,
                              size: 17,
                              })
                            ],
                              border:{
                                top: {
                                    space: 2
                                  },
                                  bottom: {
                                    space: 2
                                },
                              },
                              indent: {
                                left: 100,
                                right: 100
                              },
                                  alignment: AlignmentType.LEFT,
                          }),
                          ],
                          alignment: AlignmentType.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                            size: 5000,
                            type: WidthType.DXA,
                          },
                          background: {
                            color: "red",
                          },
                          children: [new Paragraph({
                              children: [new TextRun({
                              text:"7. ПРЕДВАРИТЕЛЬНЫЙ СРОК ИСПОЛНЕНИЯ ЗАКАЗА", 
                              size: 17,
                              background: {
                                color: "red",
                              }
                              })],
                              border:{
                                top: {
                                    space: 2
                                  },
                                left: {
                                    space: 5
                                  },
                                  bottom: {
                                    space: 2
                                },
                              },
                              indent: {
                                left: 70,
                              },
                                  alignment: AlignmentType.LEFT,
                          }),
                          ],
                          alignment: AlignmentType.CENTER,
                      }),
                      new TableCell({
                          width: {
                            size: 5000,
                            type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                              children: [new TextRun({
                            text:` ${this.objStore.time !== '' ? moment(this.objStore.time).format("DD.MM.YYYY") : ''}`, 
                              size: 17,
                              })],
                              border:{
                                top: {
                                    space: 2
                                  },
                                  bottom: {
                                    space: 2
                                },
                              },
                              indent: {
                                left: 100,
                              },
                                  alignment: AlignmentType.СENTER,
                          }),
                          ],
                          alignment: AlignmentType.CENTER,
                          pageBreakBefore: true,

                      }),
                  ]
              })
              ]
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'При самовывозе Заказчик сообщает Исполнителю дату отгрузки не ранее, чем',
                    italics: true,
                    size: 20,
                }),
                new TextRun({
                  text: ' за 2 (Два) рабочих дня.',
                    size: 20,
                    italics: true,
                    bold: true,
                })
              ],
              border:{
                  top: {
                      space: 5
                    },
                    bottom: {
                      space: 5
                  },
                },
                indent: {
                  left: 300
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Погрузка при самовывозе осуществляется',
                    size: 20,
                    italics: true,
                }),
                new TextRun({
                  text: ' силами Заказчика.',
                    size: 20,
                    bold: true,
                    italics: true,
                })
              ],
              border:{
                  top: {
                      space: 10
                    },
                    bottom: {
                      space: 5
                  },
                },
                indent: {
                  left: 300
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Гранитные элементы надгробий ( памятник ) отгружаются на установку только после',
                    size: 20,
                    italics: true,
                }),
                new TextRun({
                  text: ' ПОЛНОГО РАСЧЕТА!.',
                    size: 20,
                    bold: true,
                    italics: true,
                })
              ],
              
                indent: {
                  left: 300
                },
              
              }),
              new Paragraph({
                    text: "",
                    pageBreakBefore: true,
                }),
              new Table({
              columnWidths: [10000],
              rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 3000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                              children: [new TextRun({
                                text:"НАДПИСЬ НА КАМНЕ:",
                                size: 18,
                                bold: true,
                              })],
                              border:{
                                top: {
                                    space: 5
                                  },
                                  bottom: {
                                    space: 5
                                },
                              },
                                  alignment: AlignmentType.CENTER,
                          })
                          ],
                      }),
                  ],
              }),
              ]
              }),
              new Table({
              columnWidths: [4000, 6000],
              rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                              children: [new TextRun({
                                text:` ФАМИЛИЯ`,
                            size: 18,
                            bold: true,
                              })],
                              border:{
                            top: {
                                space: 5
                              },
                              bottom: {
                                space: 5
                            },
                          },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                          
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({
                            text:` ${this.objStore.surname}`,
                            size: 18,
                            })],
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ИМЯ`,
                                  size: 18,
                                  bold: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.name}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ОТЧЕСТВО`,
                                  size: 18,
                                  bold: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.patronymic}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` ДАТА РОЖДЕНИЯ`,
                            size: 18,
                            bold: true,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.dateBirth !== '' ? moment(this.objStore.dateBirth).format("DD.MM.YYYY") : ''}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` ДАТА СМЕРТИ`,
                            size: 18,
                            bold: true,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.dateDeath !== '' ? moment(this.objStore.dateDeath).format("DD.MM.YYYY") : ''}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` ЭПИТАФИЯ`,
                            size: 18,
                            bold: true,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({
                                    text:` ${this.objStore.epitaph}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                indent: {
                                  left: 100
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` КРЕСТ`,
                            size: 18,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.cross}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` ПОРТРЕТ (гравировка)`,
                            size: 18,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.portrait}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` ЦВЕТЫ`,
                            size: 18,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.flowers}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` РИСУНОК НА ПАМЯТНИКЕ`,
                            size: 18,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.drawing}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                            children: [new TextRun({text:` МЕДАЛЬОН`,
                            size: 18,
                            })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` Тип: ${this.objStore.locketType}, Размер: ${this.objStore.locketSize}, Форма: ${this.objStore.locketForm}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ШРИФТ на памятнике`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.font}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 4000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ОБОРОТНАЯ СТОРОНА ПАМЯТНИКА`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                      new TableCell({
                          width: {
                              size: 6000,
                              type: WidthType.DXA,
                          },
                          children: [new Paragraph({
                                  children: [new TextRun({text:` ${this.objStore.backSide}`,
                                  size: 18,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          })
                          ],
                      }),
                  ]
              }),
              
              // new TableRow({
              //     children: [
              //         new TableCell({
              //             width: {
              //                 size: 10000,
              //                 type: WidthType.DXA,
              //             },
              //             children: [
              //               new Paragraph({
              //                 children: [
              //                         new ImageRun({
              //                         data: img,
              //                         transformation: {
              //                             width: 300,
              //                             height: 300,
              //                         },
              //            }),
              //                 ]
              //               })
                      
              //             ],
              //         }),
              //     ]
              // }),
              ]
              }),
              // new Table({
              // columnWidths: [10000],
              // rows: [
              //   new TableRow({
              //     children: [
              //         new TableCell({
              //             width: {
              //                 size: 10000,
              //                 type: WidthType.DXA,
              //             },
              //             children: [
              //               new Paragraph({
              //                 children: [
              //                         new ImageRun({
              //                         // data: `require('${this.objStore.sketch.image})`,
              //                         data: this.objStore.sketch ? `${require(`../assets/${this.objStore.sketch.name}.png`)}` : '',

              //                         transformation: {
              //                             width: 200,
              //                             height: 400,
              //                         },
              //                     }),
              //                     new ImageRun({
              //                         // data: `require('${this.objStore.sketch.image})`,
              //                         data: this.objStore.cross ? `${require(`../assets/${this.objStore.cross.name}.png`)}` : '',

              //                         transformation: {
              //                             width: 30,
              //                             height: 30,
              //                         },
              //                     }),
              //                 ]
              //               }),
                            
                      
              //             ],
              //         }),
              //     ]
              // }),
              // ]
              // }),
              new Paragraph({
                          children: [new TextRun({text:`Размещение на камне:`,
                          size: 18,
                          bold: true,
                          })],
                          border:{
                          top: {
                              space: 5
                            },
                            bottom: {
                              space: 5
                          },
                        },
                        spacing: {
                            after: 100
                        },
                          alignment: AlignmentType.LEFT,
                      }),
                      new Paragraph({
                          children: [new TextRun({text:``,
                          size: 18,
                          bold: true,
                          })],
                          border:{
                          top: {
                              space: 5
                            },
                            bottom: {
                              space: 5
                          },
                        },
                        spacing: {
                            after: 1000
                        },
                          alignment: AlignmentType.LEFT,
                      }),

                      new Table({
              columnWidths: [10000],
              rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          width: {
                              size: 5000,
                              type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                                  children: [new TextRun({text:` «ИСПОЛНИТЕЛЬ»:`,
                                  size: 18,
                                  bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [new TextRun({text:` Индивидуальный предприниматель `,
                              size: 18,
                              bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                              alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [new TextRun({text:` `,
                              size: 18,
                              bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                              alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [new TextRun({text:` ШАМАК ВЛАДИМИР ВАЛЕНТИНОВИЧ `,
                              size: 18,
                              bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                              alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [
                                new TextRun({text:` __________________ `,
                              size: 18,
                              bold: true,
                                  italics: true,
                                  }),
                                  new TextRun({text:` ШАМАК В.В. `,
                              size: 18,
                              bold: true,
                                  }),
                                ],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                              alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [
                                  new TextRun({text:`                        /Подпись/ `,
                              size: 14,
                              bold: true,
                                  }),
                                ],
                                indent: {
                                  left: 400,
                                },
                                
                              alignment: AlignmentType.LEFT,
                          }),
                        ],
                        borders:{
                            top: {
                                border: false
                              },
                              bottom: {
                                border: false
                            },
                            left: {
                                border: false
                            },
                            right: {
                                border: false
                            },
                        },
                      }),
                      new TableCell({
                          width: {
                              size: 5000,
                              type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                                  children: [new TextRun({text:` «ЗАКАЗЧИК»: `,
                                  size: 18,
                                  bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                                  children: [new TextRun({text:` Камни  мною лично выбраны, размеры, эскиз и `,
                                  size: 18,
                                  bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                                  children: [new TextRun({text:` текст согласован `,
                                  size: 18,
                                  bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.LEFT,
                          }),
                          new Paragraph({
                                  children: [new TextRun({
                                  text:` ${this.objStore.fio.toUpperCase()} `,
                                  size: 18,
                                  bold: true,
                                  italics: true,
                                  })],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                                  alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [
                                new TextRun({text:` __________________ `,
                              size: 18,
                              bold: true,
                                  italics: true,
                                  }),
                                  new TextRun({text:` ${this.clientName} `,
                              size: 18,
                              bold: true,
                                  }),
                                ],
                                  border:{
                                  top: {
                                      space: 5
                                    },
                                    bottom: {
                                      space: 5
                                  },
                                },
                              alignment: AlignmentType.CENTER,
                          }),
                          new Paragraph({
                              children: [
                                  new TextRun({text:`                        /Подпись/ `,
                              size: 14,
                              bold: true,
                                  }),
                                ],
                                indent: {
                                  left: 400,
                                },
                                
                              alignment: AlignmentType.LEFT,
                          }),
                        ],
                        borders:{
                            top: {
                                // style: BorderStyle.NONE,
                                border: false
                              },
                              bottom: {
                                // style: BorderStyle.NONE,
                                border: false
                            },
                            left: {
                                // style: BorderStyle.NONE,
                                border: false
                            },
                            right: {
                                // style: BorderStyle.NONE,
                                border: false
                            },
                        },
                      }),
                  ]
              }),
              ]
              }),

            ],
          },
        ],
      });
      const mimeType =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      const fileName = "test.docx";
      Packer.toBlob(doc).then((blob) => {
        const docblob = blob.slice(0, blob.size, mimeType);
        FileSaver.saveAs(docblob, fileName);
      });
    },
  },

  // computed:{
  //   date(){
  //     return moment().format("DD.MM.YYYY")
  //   }
  // }
};
</script>