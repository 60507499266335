import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
// import FormData from "@/views/FormData.vue";
// import getAuth from "@/store/index.js";`

Vue.use(VueRouter);
const router = new VueRouter({
  history: "hash",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView
    }
    // {
    //   path: "/form",
    //   name: "formData",
    //   component: FormData
    // beforeEnter: (to, from, next) => {
    //   const accountStore = getAuth;
    //   if (accountStore.state.auth.token) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // }
    // }
  ]
});

export default router;
