<template>
    <v-container>
        <v-card flat>
      <v-card-text>
        <v-card
        elevation="2"
        outlined
        >   
        <p class=" text-h5 ml-5">Информация о заказчике</p>
        <v-row class="ma-2">
    <v-col cols="2" lg="4" md="6" sm="6">
    Номер заказа
    <v-text-field
    v-model="objClient.number"
    class=""
    dense
    outlined
>
    </v-text-field>
</v-col>   
    <v-col cols="4" lg="4" md="6" sm="6">
    Заказчик
    <v-text-field
    v-model="objClient.fio"
    class=""
    dense
    outlined
>
    </v-text-field>
</v-col>   
<v-col cols="4" lg="4" md="6" sm="6">
    Контактный телефон Заказчика
    <v-text-field-simplemask
  v-model="objClient.phone"
  v-bind:properties="{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    placeholder: '+375 (##) ###-##-##',
    dense: true,
  }"
  v-bind:options="{
    inputMask: '+### (##) ###-##-##',
    outputMask: '+### (##) ###-##-##',
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
  }"
/>
</v-col>   
</v-row>
</v-card>
      </v-card-text>
    </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CLientTab",
  data() {
    return {
      objClient: {
        number: "",
        fio: "",
        phone: "",
      },
      objCopyStore: {},
    };
  },
  mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "objClient": {
    handler() {
        for(const key in this.objClient){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.objClient[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
  },
};
</script>