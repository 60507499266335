<template>
    <v-container>
            <v-card flat>
          <v-card-text>
            <v-card
            elevation="2"
            outlined
            class="pb-2"
            >
            <p class="ml-5 text-h5">Ограда из гранита</p>
            <v-row class="ml-2 mr-2" >
                <v-col cols="3" lg="4" md="6" sm="6">
                Размеры
                <v-text-field
                v-model="objFence.dimensionsFence"
                dense
                outlined
            >
                </v-text-field>
            </v-col>  
            <v-col cols="5" lg="6" md="12" sm="12">
                <div class="d-flex">
                    <v-checkbox
                v-model="objFence.faskaFence"
                true-value="1"
                false-value="0"
                class="mt-1 pt-0 ml-1"
                dense
                outlined
            />  
                <span class="mt-1">Фаска на гранитных элементах ограды снята</span>
                </div>
                <div class="d-flex">
                <v-checkbox
                v-model="objFence.faskaFence2"
                true-value="1"
                false-value="0"
                class="mt-1 pt-0 ml-1"
                dense
                outlined
            />  
                <span class="mt-1">Фаска на гранитных элементах ограды полирована</span>
                </div>
            </v-col>  
            </v-row>
            </v-card>

            <v-card
            elevation="2"
            outlined
            class="mt-2"
            >
            <p class=" text-h5 ml-5">Столбики</p>
                <v-row class="ml-2 mr-2">
                <v-col cols="3" lg="4" md="6" sm="6">
                    Размеры
                    <v-text-field
                    v-model="objFence.dimensionsColumns"
                    dense
                    outlined
                >
                    </v-text-field>
                </v-col>   
                <v-col cols="3" lg="4" md="6" sm="6">
                    Количество штук
                    <v-text-field
                    v-model="objFence.quantityColumns"
                    dense
                    outlined
                >
                    </v-text-field>
                </v-col>  
                <v-col cols="5" lg="4" md="12" sm="12">
                    <div class="d-flex">
                        <v-checkbox
                    v-model="objFence.faskaColumns"
                    true-value="1"
                    false-value="0"
                    class="mt-1 pt-0 ml-1"
                    dense
                    outlined
                />  
                    <span class="mt-1">Фаска по краям снята</span>
                    </div>
                    <div class="d-flex">
                    <v-checkbox
                    v-model="objFence.faskaColumns2"
                    true-value="1"
                    false-value="0"
                    class="mt-1 pt-0 ml-1"
                    dense
                    outlined
                />  
                    <span class="mt-1">Фаска по краям полирована</span>
                    </div>
                </v-col>  
                </v-row>
            </v-card>

            <v-card
            elevation="2"
            outlined
            class="mt-2"
            >
             <p class=" text-h5 ml-5 mr-2">Цоколь</p>
                <v-row class="ml-2 mr-2">
                <v-col cols="3" lg="4" md="6" sm="6">
                    Размеры
                    <v-text-field
                    v-model="objFence.dimensionsPlinth"
                    dense
                    outlined
                >
                    </v-text-field>
                </v-col>   
                <v-col cols="3" lg="4" md="6" sm="6">
                    Количество штук
                    <v-text-field
                    v-model="objFence.quantityPlinth"
                    dense
                    outlined
                >
                    </v-text-field>
                </v-col> 
                <v-col cols="5" lg="4" md="12" sm="12">
                    <div class="d-flex">
                        <v-checkbox
                    v-model="objFence.faskaPlinth"
                    true-value="1"
                    false-value="0"
                    class="mt-1 pt-0 ml-1"
                    dense
                    outlined
                />  
                    <span class="mt-1">Фаска по краям снята</span>
                    </div>
                    <div class="d-flex">
                    <v-checkbox
                    v-model="objFence.faskaPlinth2"
                    true-value="1"
                    false-value="0"
                    class="mt-1 pt-0 ml-1"
                    dense
                    outlined
                />  
                    <span class="mt-1">Фаска по краям полирована</span>
                    </div>
                </v-col>    
                </v-row>
            </v-card>
          </v-card-text>
        </v-card>
        </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FenceTab",
  data() {
    return {
      objFence: {
        dimensionsFence: '',
        faskaFence: '',
        faskaFence2: '',
        dimensionsColumns: '',
        quantityColumns: '',
        faskaColumns: '',
        faskaColumns2: '',
        dimensionsPlinth: '',
        quantityPlinth: '',
        faskaPlinth: '',
        faskaPlinth2: '',
      },
      objCopyStore: {},
    };
  },
  mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "objFence": {
    handler() {
        for(const key in this.objFence){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.objFence[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
  },
};
</script>