<template>
    <v-container>
        <v-card flat>
          <v-card-text>
            <v-card
            elevation="2"
            outlined
            >   
    <v-row class="ml-2 mr-2">
    <v-col cols="4" md="6" sm="12">
        Название кладбища
        <v-text-field
        v-model="cemeteryObj.cemetery"
        dense
        outlined>
        </v-text-field>
    </v-col>
    </v-row>
    <v-row class="ml-2 mr-2">
        <v-col cols="2" lg="2" md="3" sm="6">
        Сектор
        <v-text-field
        v-model="cemeteryObj.sector"
        dense
        outlined
    >
        </v-text-field>
    </v-col>   
    <v-col cols="2" lg="2" md="3" sm="6">
        Участок
        <v-text-field
        v-model="cemeteryObj.plot"
        dense
        outlined
    >
        </v-text-field>
    </v-col>   
    <v-col cols="2" lg="2" md="3" sm="6">
        Ряд
        <v-text-field
        v-model="cemeteryObj.row"
        dense
        outlined
    >
        </v-text-field>
    </v-col>   
    <v-col cols="2" lg="2" md="3" sm="6">
        Место
        <v-text-field
        v-model="cemeteryObj.place"
        dense
        outlined
    >
        </v-text-field>
    </v-col>   
    </v-row>
            </v-card>
          </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'CemeteryTab',
    data() {
        return {
            cemeteryObj: {
                cemetery: '',
                sector: '',
                plot: '',
                row: '',
                place: ''
            },
            objCopyStore: {},
        }
    },
    mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "cemeteryObj": {
    handler() {
        for(const key in this.cemeteryObj){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.cemeteryObj[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
  },
}
</script>