<template>
    <v-container>
            <v-card flat>
          <v-card-text>
            <v-card
            elevation="2"
            outlined
            >   
            <p class=" text-h5 ml-2 mr-2">Стела</p>
            <v-row class="ml-2 mr-2">
                <v-col cols="3" lg="4" md="6" sm="6">
                Эскиз
                <v-text-field
                    v-model="objEquipment.sketch"
                    dense
                    outlined
                    ></v-text-field>
                    <!-- <v-autocomplete
                    v-model="objEquipment.sketch"
                    :items="sketchItems"
                    return-object
                    item-value="image"
                    item-text="name"
                    dense
                    outlined
                    clearable
                    >
                    
                    <template v-slot:selection="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="d-flex">
                        <v-img :src="imagePam(item)" width="20" class="mr-2"></v-img>
                    <span> {{ item.name }} </span>
                    </div>
                    
                  </template>
                    </v-autocomplete> -->
                </v-col>   
                 <v-col cols="3" lg="4" md="6" sm="6">
                Размеры
                <v-text-field
                v-model="objEquipment.dimensionsStele"
                dense
                outlined
            >
                </v-text-field>
            </v-col>   
            <v-col cols="3" lg="4" md="6" sm="6">
                Гранит
                <v-autocomplete
                v-model="objEquipment.granit"
                :items="granitItems"
                dense
                outlined
                />
            </v-col>   
            <v-col cols="5" lg="6" md=12 sm="12" class="d-flex">
                <v-checkbox
                v-model="objEquipment.polishedBevelStella"
                true-value="1"
                false-value="0"
                dense
                outlined
                class="mt-0 pt-0 ml-1"
            />  
                <span class="mt-0">Фаска задней стороны стелы полирована</span>
                
            </v-col>   
            </v-row>
            </v-card>

            <v-card
            elevation="2"
            outlined
            class="mt-2"
            >   
            <p class=" text-h5 ml-2 mt-1">Подставка</p>
            <v-row class="ml-2 mr-2">
                <v-col cols="3" lg="4" md="6" sm="6">
                Размеры
                <v-text-field
                v-model="objEquipment.dimensionsPedestal"
                dense
                outlined
            >
            </v-text-field>
            </v-col>   
            <v-col cols="5" lg="5" md="12" sm="12">
            <div class="d-flex">
                <v-checkbox
                    v-model="objEquipment.polishedBevelPedestal"
                    true-value="1"
                    false-value="0"
                    class="mt-1 pt-0 ml-1"
                    dense
                    outlined
                />  
            <span class="mt-1">Фаска по краям тумбы снята</span>
            </div>
                <div class="d-flex">
                <v-checkbox
                v-model="objEquipment.polishedBevelPedestal2"
                true-value="1"
                false-value="0"
                class="mt-1 pt-0 ml-1"
                dense
                outlined
            />  
            <span class="mt-1">Фаска по краям тумбы полирована</span>
            </div>
            </v-col>   
            </v-row>
            </v-card>

            <v-card
            elevation="2"
            outlined
            class="mt-2"
            >
            <p class=" text-h5 ml-5 mt-1">Цветник</p>
            <v-row class="ml-2 mr-2">
            <v-col cols="3" lg="4" md="6" sm="6">
            Размеры
            <v-text-field
            v-model="objEquipment.dimensionsTsvetnik"
            dense
            outlined
            >
            </v-text-field>
        </v-col>   
        <v-col cols="5" lg="5" md="12" sm="12">
            <div class="d-flex">
                <v-checkbox
            v-model="objEquipment.faskaTsvetnik1"
            true-value="1"
            false-value="0"
            class="mt-1 pt-0 ml-1"
            dense
            outlined
            />  
            <span class="mt-1">Торцы поперечки цветника полированы</span>
            </div>
            <div class="d-flex">
            <v-checkbox
            v-model="objEquipment.faskaTsvetnik2"
            true-value="1"
            false-value="0"
            class="mt-1 pt-0 ml-1"
            dense
            outlined
        />  
            <span class="mt-1">Фаска по краям цветника снята</span>
            </div>
            <div class="d-flex">
            <v-checkbox
            v-model="objEquipment.faskaTsvetnik3"
            true-value="1"
            false-value="0"
            class="mt-1 pt-0 ml-1"
            dense
            outlined
        />
            <span class="mt-1">Фаска по краям цветника полирована</span>
            </div>
        </v-col>
        </v-row>
            </v-card>

            <v-card
            elevation="2"
            outlined
            class="mt-2"
            >
            <p class=" text-h5 ml-5">Надгробная вставка/Плита</p>
            <v-row class="ml-2 mr-2">
                <v-col cols="3" lg="4" md="6" sm="6">
                Размеры
                <v-text-field
                v-model="objEquipment.dimensionsVstavka"
                dense
                outlined
            >
                </v-text-field>
            </v-col>
            <v-col cols="4" lg="4" md="12" sm="12">
                <div class="d-flex">
                    <v-checkbox
                v-model="objEquipment.faskaVstavka1"
                true-value="1"
                false-value="0"
                class="mt-0 pt-0 ml-1"
                dense
                outlined
            />  
                <span class="mt-1">Фаска на плите снята</span>
                </div>
                <div class="d-flex">
                <v-checkbox
                v-model="objEquipment.faskaVstavka2"
                true-value="1"
                false-value="0"
                class="mt-0 pt-0 ml-1"
                dense
                outlined
            />  
                <span class="mt-1">Фаска на плите полирована</span>
                </div>
            </v-col>    
            </v-row>
            </v-card>
          </v-card-text>
        </v-card>
        </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'EquipmentTab',
    data() {
        return {
            objEquipment: {
                sketch: '',
                dimensionsStele: '',
                granit: '',
                polishedBevelStella: '',
                dimensionsPedestal: '',
                polishedBevelPedestal: '',
                polishedBevelPedestal2: '',
                dimensionsTsvetnik: '',
                faskaTsvetnik1: '',
                faskaTsvetnik2: '',
                faskaTsvetnik3: '',
                dimensionsVstavka: '',
                faskaVstavka1: '',
                faskaVstavka2: '',

            },
            objCopyStore: {},
            granitItems: [
                'Балтик Браун',
                'Куру Грей',
                'Амадеус',
                'гранит Возрождение',
                'Сюськюяансаари',
                'Гранатовый Амфиболит',
                'Покостовский',
                'Империал Ред',
                'Ладожский',
                'Капустинский',
                'Карельский',
                'Дымовский',
                'Балморал Ред',
                'Аврора',
                'Китайский',
                'Украинский',
                'Мансуровский',
                'Балтик Грин',
                'Лието Ред',
                'Масловский',
                'Блу Перл',
                'Сильвер Перл',
                'Роял Грин',
                'Токовский',
                'Лезниковский',
                'Белла Уайт',
                '',

            ],
        }
    },
    mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "objEquipment": {
    handler() {
        for(const key in this.objEquipment){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.objEquipment[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
    // imagePam(item){
    //         return require(`../assets/${item.name}.png`)
    //     }
      
  },
}
</script>