<template>
    <v-container>
        <v-card
        elevation="2"
        outlined
        >
        <p class="ml-5 text-h5">Дополнение</p>
        <v-row class="ml-2 mr-2">
            <v-col cols="4" lg="4" md="8" sm="12">
                <div class="d-flex justify-space-between">
                    <span class="mt-2">1.Сверление отверстий</span>
                    <div class="ml-4"><v-checkbox v-model="objAddition.holes" true-value="1" false-value="0" outlined dense></v-checkbox></div>
                </div>
                <div class="d-flex justify-space-between">
                    <span class="mt-2">2.Отверстие под медальон</span>
                    <div class="ml-4"><v-checkbox v-model="objAddition.medallionHole" true-value="1" false-value="0" outlined dense></v-checkbox></div>
                </div>
                <div class="d-flex justify-space-between">
                    <span class="mt-2">3.Увеличение фотографии</span>
                    <div class="ml-4"><v-checkbox v-model="objAddition.enlargementPhoto" true-value="1" false-value="0" outlined dense></v-checkbox></div>
                </div>
                <div class="d-flex justify-space-between">
                    <span class="mt-2">4.Забелить портрет</span>
                    <div class="ml-4"><v-checkbox v-model="objAddition.whitenPortrait" true-value="1" false-value="0" outlined dense></v-checkbox></div>
                </div>
                <div class="d-flex justify-space-between">
                    <span class="mt-2">5.Забелить текст</span>
                    <div class="ml-4"><v-checkbox v-model="objAddition.whitenText" true-value="1" false-value="0" outlined dense></v-checkbox></div>
                </div>
                <div class="d-flex justify-space-between">
                    <span class="mt-2">6.Забелить выбитый дизайн</span>
                    <div class="ml-4"><v-checkbox v-model="objAddition.whitenDesign" true-value="1" false-value="0" outlined dense></v-checkbox></div>
                </div>
            </v-col>
            <v-col cols="6" lg="6" md="12" sm="12" class="d-none d-lg-block">
                8.Особенности заказа
                <v-textarea v-model="objAddition.orderFeatures" outlined></v-textarea>
            </v-col>
        </v-row>
        <v-row class="ml-2 mr-2">
            <v-col cols="3" lg="4" md="6" sm="6">
                    <span class="mt-2">7.Предварительный срок исполнения заказа</span>
            </v-col>
            <v-col cols="3" lg="4" md="6" sm="6">
                    <div class="ml-4"><v-text-field v-model="objAddition.time" type="date" outlined dense></v-text-field></div>
            </v-col>
        </v-row>
        <v-row class="ml-2 mr-2 d-lg-none d-md-block d-sm-block">
            <v-col cols="6" lg="6" md="12" sm="12" >
                8.Особенности заказа
                <v-textarea v-model="objAddition.orderFeatures" outlined></v-textarea>
            </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data () {
        return{
        objAddition: {
            holes: '',
            medallionHole: '',
            enlargementPhoto: '',
            whitenPortrait: '',
            whitenText: '',
            whitenDesign: '',
            time: '',
            orderFeatures: ''
        },
        objCopyStore: {},
    }
},
mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "objAddition": {
    handler() {
        for(const key in this.objAddition){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.objAddition[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
  },
}
</script>