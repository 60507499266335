var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('p',{staticClass:"text-h5 ml-5"},[_vm._v("Информация о заказчике")]),_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"2","lg":"4","md":"6","sm":"6"}},[_vm._v(" Номер заказа "),_c('v-text-field',{attrs:{"dense":"","outlined":""},model:{value:(_vm.objClient.number),callback:function ($$v) {_vm.$set(_vm.objClient, "number", $$v)},expression:"objClient.number"}})],1),_c('v-col',{attrs:{"cols":"4","lg":"4","md":"6","sm":"6"}},[_vm._v(" Заказчик "),_c('v-text-field',{attrs:{"dense":"","outlined":""},model:{value:(_vm.objClient.fio),callback:function ($$v) {_vm.$set(_vm.objClient, "fio", $$v)},expression:"objClient.fio"}})],1),_c('v-col',{attrs:{"cols":"4","lg":"4","md":"6","sm":"6"}},[_vm._v(" Контактный телефон Заказчика "),_c('v-text-field-simplemask',{attrs:{"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    placeholder: '+375 (##) ###-##-##',
    dense: true,
  },"options":{
    inputMask: '+### (##) ###-##-##',
    outputMask: '+### (##) ###-##-##',
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
  }},model:{value:(_vm.objClient.phone),callback:function ($$v) {_vm.$set(_vm.objClient, "phone", $$v)},expression:"objClient.phone"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }