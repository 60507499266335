<template>
      <v-container>
          <div class="window-height window-width row justify-center items-center">
              <div class=" formLogin">
                  <div class="d-flex row justify-between">
                      <p class="text-h5 ml-3 mt-2">Вход</p>
                  </div>
              <v-form
              @reset="clearForm"
              
              >
              <v-text-field
                  outlined
                  dense
                  v-model="login"
                  label="Логин *"
                  lazy-rules
                  :rules="[ val => val && val.length > 0 || 'Введите логин']"
                  prepend-inner-icon="mdi-account"
              />
              <v-text-field
                  outlined
                  dense
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  v-model="password"
                  label="Пароль *"
                  lazy-rules
                  :rules="[ val => val && val.length > 0 || 'Введите пароль']"
                  prepend-inner-icon="mdi-lock"
                   @click:append="showPass = !showPass"
              >
              </v-text-field>

    <div>
      <v-btn @click="loginIn" color="primary">Вход</v-btn>
      <v-btn type="reset" color="primary" class="q-ml-sm float-right" >Очистить</v-btn>
    </div>
  </v-form>
  <div>
    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        :value="true"
        absolute
        top
        color="red"
        outlined
        centered
      >
         <strong>Введен неверный логин или пароль</strong>
      </v-snackbar>
  </div>
  
</div>
          </div>
      </v-container>
</template>

<script>
import {  mapMutations, mapGetters } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      showPass: false,
      login: "",
      password: "",
      showPassword: false,
      isLogin: true,
      snackbar: false
    };
  },
  computed: {
    ...mapGetters(["getAuth"]),
  },
  methods: {
    ...mapMutations(["setAuth"]),
    clearForm() {
      this.login = "";
      this.password = "";
    },
    loginIn() {
      if (this.login === 'admin' && this.password === 'admin') {
        this.setAuth({token: 'token'});
        // this.$router.push('/form');
        localStorage.setItem('token', 'token');
      }else{
        this.snackbar = true
      }
    }
  }
};
</script>
<style scoped>
.formLogin {
  margin-top: 221px;
  border-radius: 5px;
  /* background-color: rgb(229, 220, 220); */
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 20px;
}
</style>
