<template>
<v-container>
    <v-card>
    <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-toolbar-title>Информация по заказу</v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical>
        <v-tab class="d-flex justify-space-between">
        <v-icon left>
          mdi-account
        </v-icon>
        Заказчик
      </v-tab>
      <v-tab class="d-flex justify-space-between">
        <v-icon left>
            mdi-church
        </v-icon>
        Кладбище
      </v-tab>
      <v-tab class="d-flex justify-space-between">
        <v-icon left>
            mdi-coffin
        </v-icon>
        Комплект
      </v-tab>
      <v-tab class="d-flex justify-space-between">
        <v-icon left>
            mdi-pier
        </v-icon>
        Ограда
      </v-tab>
      <v-tab class="d-flex justify-space-between">
        <v-icon left>
            mdi-lava-lamp
        </v-icon>
        Вазы
      </v-tab>
      <v-tab class="d-flex justify-space-between">
        <v-icon left>
            mdi-file-document-edit
        </v-icon>
        Дополнение
      </v-tab>
      <v-tab class="d-flex justify-space-between">
        <v-icon left>
            mdi-account-box
        </v-icon>
        Надпись на камне
      </v-tab>

    <v-tab-item>
      <ClientTab />
    </v-tab-item>

    <v-tab-item>
       <CemeteryTab />
    </v-tab-item>

    <v-tab-item>
       <EquipmentTab />
    </v-tab-item>

    <v-tab-item>
       <FenceTab />
    </v-tab-item>

    <v-tab-item>
       <VasesTab />
    </v-tab-item>

    <v-tab-item>
       <AdditionTab />
    </v-tab-item>

    <v-tab-item>
       <TextOnStoneTab />
    </v-tab-item>

    </v-tabs>
    <div class="d-flex justify-end mt-4 mr-4">
        <load-file />
    </div>
  </v-card>
  
</v-container>
    
</template>
<script>
import loadFile from './loadFile.vue';
import  ClientTab  from './ClientTab.vue';
import CemeteryTab from './CemeteryTab.vue';
import EquipmentTab from './EquipmentTab.vue';
import FenceTab from './FenceTab.vue';
import VasesTab from './VasesTab.vue';
import AdditionTab from './AdditionTab.vue';
import TextOnStoneTab from './TextOnStoneTab.vue';


export default{
    name: 'FormData',
    components: {
        loadFile, 
        ClientTab,
        CemeteryTab,
        EquipmentTab,
        FenceTab,
        VasesTab,
        AdditionTab,
        TextOnStoneTab
    },
  
    data(){
        return{
            tab: '',
        }
    },
}
</script>
<style>
    #card {
        font-size: 1.2vh!important;
    }
</style>