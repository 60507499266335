<template>
<div>
  <LoginForm v-if="!token && getAuth.token == null" />
  <FormData v-if="token  || getAuth.token"/>
  </div>
</template>

<script>
import LoginForm from '@/views/LoginForm.vue'
import FormData from '@/views/FormData.vue'

import { mapMutations, mapGetters } from "vuex";

export default {
  components: { LoginForm, FormData },

  data(){
    return {}
  },
  computed: {
    ...mapGetters(["getAuth"]),
    token(){
      return localStorage.getItem('token')
    }
  },

  methods: {
    ...mapMutations(["setAuth"]),
    
  },
};
</script>