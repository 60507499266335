<template>
    <v-container>
        <v-card
        elevation="2"
        outlined
        >
        <p class="ml-5 text-h5">Надпись на камне</p>
        <v-row class="ml-2 mr-2">
            <v-col cols="12">
            <v-row>
                <v-col cols="4">
                    Фамилия
                    <div><v-text-field v-model="obj.surname" outlined dense></v-text-field></div>
                </v-col>
                <v-col cols="4">
                    Имя
                    <div><v-text-field v-model="obj.name" outlined dense></v-text-field></div>
                </v-col>
                <v-col cols="4">
                    Отчество
                    <div><v-text-field v-model="obj.patronymic" outlined dense></v-text-field></div>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="4">
                    Дата рождения
                    <div><v-text-field v-model="obj.dateBirth" type="date" outlined dense></v-text-field></div>
                </v-col>
                <v-col cols="4">
                    Дата смерти
                    <div><v-text-field v-model="obj.dateDeath" type="date" outlined dense></v-text-field></div>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    Эпитафия
                    <div>
                        <v-autocomplete
                        v-model="obj.epitaph" 
                        :items="listEpitafii" 
                        cols="4"
                        outlined
                        item-value="name"
                        item-text="name"
                        dense>
                    </v-autocomplete>
                    </div>

                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    Крест
                    <div>
                        <v-autocomplete 
                    v-model="obj.cross" 
                    :items="listCross" 
                    outlined 
                    dense
                    >
                    <!-- <template v-slot:selection="{ item }">
                        <span>{{ item.title }}</span>
                    </template> -->
                    <!-- <template v-slot:item="{ item }">
                        <div class="d-flex">
                            <v-img :src="imagePam(item)" width="20" class="mr-2"></v-img>
                            <span> {{ item.title }} </span>
                        </div>
                        </template>  -->
                    </v-autocomplete>
                    </div>
                </v-col>
                <v-col cols="6">
                    Портрет(гравировка)
                    <div><v-select v-model="obj.portrait" :items="['Портрет','Гравировка']" outlined dense></v-select></div>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    Цветы
                    <div><v-autocomplete v-model="obj.flowers" :items="['Розы','Гвоздики','Лавровая ветвь','Рисунок заказчика']" outlined dense></v-autocomplete></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    Рисунок на памятнике
                    <div><v-text-field v-model="obj.drawing" outlined dense></v-text-field></div>
                </v-col>
            </v-row>
            <v-row>
                <div>
                <span class="mt-2 ml-5">Медальон</span>
                </div>
                <v-col cols="12">
                    <div class="d-flex">
                        <div>
                        Тип
                        <v-autocomplete v-model="obj.locketType" :items="['Металл', 'Керамика']" outlined dense></v-autocomplete>
                        </div>
                        <div class="pl-2"> 
                        Р-р
                        <v-autocomplete v-model="obj.locketSize" :items="['9х12', '11х15',	'13х18','18х24','20х30','25х30','30х40']" outlined dense></v-autocomplete>
                        </div>
                        <div class="pl-2">
                        Форма
                        <v-autocomplete v-model="obj.locketForm" :items="['Овальный','Прямоугольный']" outlined dense></v-autocomplete>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    Шрифт на памятнике
                    <div><v-autocomplete v-model="obj.font" :items="['Прямой','Наклонный','Прописной','Старославянский','Шрифт заказчика']" outlined dense></v-autocomplete></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    Оборотная сторона памятника
                    <div><v-text-field v-model="obj.backSide" outlined dense></v-text-field></div>
                </v-col>
            </v-row>
            </v-col>
        </v-row>
        </v-card>
    </v-container>
</template>

<script>
import epitafii from '@/dataInfo/epitafii';
// import cross from '@/dataInfo/cross';
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            listEpitafii: epitafii,
            obj: {
                surname: '',
                name: '',
                patronymic: '',
                dateBirth: '',
                dateDeath: '',
                epitaph: '',
                cross: '',
                portrait: '',
                flowers: '',
                drawing: '',
                locketType: '',
                locketSize: '',
                locketForm: '',
                font: '',
                backSide: '',
            },
            listCross: ['Православный', 'Католический', 'Старославянский'],
        // listCross: [
        //     {title: 'Православный', name: 'krest4'},
        //     {title: 'Православный', name: 'prkrest'},
        //     {title: 'Католический', name: 'prkrest'},
        //     {title: 'Старославянский', name: 'prkrest'},
        //     {title: 'logo', name: 'logo'},
        //     {title: 'icon', name: 'icon'},
        //     {title: 'icon', name: 'krest12'},
        //     {title: 'icon', name: 'logo1'}
        //     // {title: 'православный', name: 'krest1'},
        //    ],
        sketchItems: [
            {
                name: 'podst',
                image: '../assets/podst.png',
            },
            {
                name: 'logo',
                image: '../assets/logo.png',
            },
            {
                name: 'A2',
                image: '../assets/a2.png',
            },
            
          
            
        ],
            objCopyStore: {},
        }
    },
    mounted() {
    this.objCopyStore = this.objStore;
  },
  watch: {
  "obj": {
    handler() {
        for(const key in this.obj){
          if (Object.prototype.hasOwnProperty.call(this.objCopyStore, key)) {
              this.objCopyStore[key] = this.obj[key]
            }
        }
        this.setObj(this.objCopyStore)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      objStore: "getObj",
    }),
  },
  methods: {
    ...mapActions({
      setObj: "setObj",
    }),
    // imagePam(item){
    //         return require(`../assets/${item.name}.png`)
    //     }
      
  },
}
</script>