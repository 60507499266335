import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    obj: {
      cemetery: "",
      number: "",
      fio: "",
      phone: "",
      sector: "",
      plot: "",
      row: "",
      place: "",
      sketch: "",
      dimensionsStele: "",
      polishedBevelStella: "",
      dimensionsPedestal: "",
      granit: "",
      faska: "",
      polishedBevelPedestal: "",
      polishedBevelPedestal2: "",
      dimensionsTsvetnik: "",
      faskaTsvetnik1: "",
      faskaTsvetnik2: "",
      faskaTsvetnik3: "",
      dimensionsVstavka: "",
      faskaVstavka1: "",
      faskaVstavka2: "",
      dimensionsFence: "",
      faskaFence: "",
      faskaFence2: "",
      dimensionsColumns: "",
      quantityColumns: "",
      faskaColumns: "",
      faskaColumns2: "",
      dimensionsPlinth: "",
      quantityPlinth: "",
      faskaPlinth: "",
      faskaPlinth2: "",
      dimensionsVase: "",
      nameVase: "",
      holes: false,
      medallionHole: false,
      enlargementPhoto: false,
      whitenPortrait: false,
      whitenText: false,
      whitenDesign: false,
      orderFeatures: "",
      time: "",
      surname: "",
      name: "",
      patronymic: "",
      dateBirth: "",
      dateDeath: "",
      epitaph: "",
      cross: "",
      portrait: "",
      flowers: "",
      drawing: "",
      locketType: "",
      locketSize: "",
      locketForm: "",
      font: "",
      backSide: ""
    },
    auth: {
      token: null
    }
  },
  getters: {
    getObj: (state) => {
      return state.obj;
    },
    getAuth: (state) => {
      return state.auth;
    }
  },
  mutations: {
    setObj: (state, obj) => {
      state.obj = obj;
    },
    setAuth: (state, auth) => {
      state.auth = auth;
    }
  },
  actions: {
    setObj: ({ commit }, obj) => {
      commit("setObj", obj);
    },
    setAuth: ({ commit }, auth) => {
      commit("setAuth", auth);
    }
  },
  modules: {}
});
