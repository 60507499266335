const epitafii = [
  { name: "Помним, скорбим…" },
  { name: "Помним, любим, скорбим…" },
  { name: "Опустела без тебя земля." },
  { name: "Мир без тебя пуст." },
  { name: "Сильнее смерти только память." },
  { name: "Боже упокой Раба своего." },
  { name: "Земной путь краток, память вечна." },
  { name: "И сердцу больно и горю нет конца." },
  { name: "Тепло души твоей осталось с нами." },
  { name: "Ты ушёл (ушла) из жизни, а из сердца нет." },
  { name: "Нет больше горя, чем горечь утраты." },
  { name: "Вечная память о тебе в сердцах родных." },
  { name: "Там, где память — смерти нет!" },
  { name: "С тобой ушел кусочек нашей жизни!" },
  { name: "С любовью и скорбью." },
  { name: "Слова бессильны…" },
  { name: "Для ближних жил (жила), не для себя." },
  { name: "Вечная память." },
  { name: "С любовью и скорбью от любящих сердец." },
  { name: "В сердце и в памяти." },
  { name: "Ты — путеводная звезда Для всех кто помнит, любит, чтит." },
  { name: "Дороже не было тебя, И никогда уже не будет!" },
  { name: "Неизлечима боль разлуки — Разлуки той, что навсегда." },
  {
    name: "Тот (та), чьи мечты стали жизнью. Тот (та), чья жизнь зажигает мечты!"
  },
  { name: "От нас ушла (ушел) ты за мгновенье, А боль осталась навсегда…" },
  { name: "Тепло души твоей Осталось вместе с нами." },
  { name: "Ты вечно будешь жить В сердцах родных и близких." },
  { name: "Мы остались без тебя, Ты остался (осталась) с нами…" },
  { name: "Не зарастет на сердце рана — Прольется чистыми слезами." },
  { name: "Великой скорби не измерить, Слезами горю не помочь." },
  { name: "Любимые не умирают, Лишь рядом быть перестают." },
  { name: "С любимыми не расстаются, лишь рядом быть перестают." },
  { name: "Ты не уйдёшь из жизни нашей Пока мы живы — с нами ты." },
  { name: "Светлая память о тебе навсегда останется в наших сердцах." },
  { name: "Безвременно прервалась жизни нить, Не дав тебе мечты осуществить…" },
  { name: "Ты – память счастья, Что умчалось прочь." },
  { name: "Ты вечно с нами, дорогой наш (дорогая), С тобой скорбящие сердца." },
  { name: "Тому, кто дорог был при жизни От тех, кто помнит и скорбит." },
  { name: "Из жизни ты ушёл (ушла) мгновенно, Но с нами будешь ты всегда." },
  { name: "Ты живёшь и будешь жить В памяти любящих тебя." },
  { name: "Дай Бог, чтобы мир другой Принес тебе покой." },
  { name: "Как вкус у смерти безупречен В отборе лучших среди нас…" },
  { name: "Бесконечно любим, Ты всегда в наших сердцах." },
  { name: "Вернуть нельзя, Забыть невозможно." },
  { name: "За пределами жизни и мира Все равно не расстанусь с тобой." },
  {
    name: "Здесь та любовь, что счастье подарила, Здесь та печаль, что мудрость принесла."
  },
  { name: "Как хочется кричать от боли, Что нет тебя на свете боле!" },
  { name: "Какой светильник разума угас! Какое сердце биться перестало!" },
  {
    name: "Не выразить словами всей скорби и печали. В сердцах и памяти всегда ты с нами."
  },
  { name: "Слова и слезы бессильны передать Всю глубину нашего горя." },
  {
    name: "Настало время сердцу быть в покое, Взяла земля свое земное. Но как же трудно нам тебя терять, Смириться с горем, жить опять."
  },
  {
    nname:
      "Догорит прощальная свеча, И глаза наполнятся слезами. Трудно жить на свете без тебя И поверить в то, что ты не с нами."
  },
  {
    name: "Тот день, когда погас твой взор И сердце перестало биться, Для нас стал/(был) самым страшным днем, И никогда с ним не смириться!"
  },
  {
    name: "Не найти в мире слов описать наше горе. Не найти в мире силы, чтоб тебя подняла. Наших слез не вместит даже море. Как жестока судьба, что тебя отняла."
  }
];
export default epitafii;
